.video {
	display: inline-block;
	position: relative;
	overflow: hidden;
	border: 1px solid gray;
	padding: 0px;
	margin: 0 10px;

	.cover-panel {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		align-items: center;
		justify-content: center;
	}

	&.cover .cover-panel {
		display: flex;

		& > .play-icon {
			display: block;
			font-size: 60px;
			width: 100px;
			height: 100px;
			color: white;
			border-radius: 50%;
			text-align: center;
			line-height: 100px;
			padding-left: 5px;
			background-color: rgba(100, 100, 100, 0.7);
			opacity: 0.5;
			transition: opacity 0.3s;
		}
	}

	&.cover:hover .cover-panel > .play-icon {
		opacity: 1;
	}

	.video-controls {
		display: flex;
		flex-wrap: 	wrap;
		background-color: rgba(0, 0, 0, 0.8);
		color: white;
		width: 100%;
		height: 37px;
		bottom: -35px;
		position: absolute;
		transition: bottom 0.5s 1s;
	}

	&.cover .video-controls {
		display: none;
	}

	&:hover .video-controls {
		bottom: 0px;
		transition: bottom 0.3s;
	}

	video {
		display: block;
	}

	.barpanel {
		background-color: gray;
		position: relative;
		width: 100%;
		height: 5px;
		cursor: pointer;

		transition: all 0.2s;

		&:hover {
			height: 9px;
			top: -2px;
			margin-bottom: -4px;
		}
	}

	.bar {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
	}

	.bar.buffer {
		background-color: white;
		right: auto;
	}

	.bar.current {
		background-color: red;
		right: auto;
	}

	.volumepanel {
		position: relative;
		width: 120px;
		height: 15px;
		align-self: center;
		margin-right: 16px;
		margin-left: 8px;

		.volume {
			top: 5px;
			bottom: 6px;
			background-color: red;
		}

		.bcg {
			top: 5px;
			bottom: 6px;
			background-color: gray;
		}

		.pin > span {
			position: absolute;
			top: 1px;
			right: 0;
			width: 12px;
			height: 12px;
			margin-right: -6px;
			background-color: red;
			border-radius: 6px;
		}
	}

	.btn {
		color: gray;
		width: 32px;
		height: 32px;
		box-sizing: border-box;
		font-size: 20px;
		padding: 6px;
		transition: color 0.2s;
		line-height: 20px;
		border: none;
		border-radius: 0;

		&:hover {
			color: white;
			transition: color 0.2s;
		}
	}

	.info {
		font-size: 16px;
		padding: 3px 5px 0 5px;
		align-self: center;

		& > b {
			font-weight: normal;
			color: rgb(200, 200, 200);
		}
	}

	.sep {
		width: 20px;
		height: 32px;

		&.right {
			margin-left: auto;
		}
	}

	i.fas {
		font-style: normal;
	}
}

.video .btn.unmute { display: none; }
.video.muted .btn.unmute { display: block; }
.video.muted .btn.mute { display: none; }

.video.stopped .btn.stop { display: none; }
.video.playing .btn.play { display: none; }
.video.paused .btn.pause { display: none; }

